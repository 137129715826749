import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, find, head } from '../../lib/nodash';
import { storyblokEditable } from 'gatsby-source-storyblok';

import ProductCard from './ProductCard';
import {
  findSampleVariant,
  getProductPath,
  findMetafieldValue,
} from '../../lib/product';
import findImageForProduct from '../../lib/findImageForProduct';
import findImage from '../../lib/findImage';
import useProductFromContextOrPicker from './useProductFromContextOrPicker';

const DynamicProductCard = ({ blok, productFromStory, collection }) => {
  const { showDescription, hoverOverlay, product } = blok;
  const source =
    get('item', productFromStory) || get('item', product) || collection;

  const { productCollection, variants, entity } = useProductFromContextOrPicker(
    {
      product,
      collection,
    }
  );

  const collectionImageField = findMetafieldValue(
    'collection_image',
    get('metafields', productCollection)
  );

  const heroImageField = findMetafieldValue(
    'hero_image',
    get('metafields', productCollection)
  );
  const collectionImage = productCollection
    ? get(
        'original_src',
        Array.isArray(collectionImageField)
          ? head(collectionImageField)
          : collectionImageField
      )
    : findImageForProduct(get('images', source), get('productType', source));

  const heroImage = productCollection
    ? get(
        'original_src',
        Array.isArray(heroImageField) ? head(heroImageField) : heroImageField
      )
    : findImage(get('images', source), '_hero');

  const description = productCollection
    ? get(
        'value',
        find(
          { key: 'short_description', namespace: 'collection' },
          get('metafields', productCollection)
        )
      )
    : get('description', source);

  const sampleVariant = findSampleVariant(variants);

  return (
    <ProductCard
      {...storyblokEditable(blok)}
      title={get('title', source)}
      collectionImageUrl={collectionImage}
      imageUrl={heroImage}
      description={description}
      showDescription={showDescription}
      overlay={hoverOverlay}
      variants={variants}
      sampleVariant={sampleVariant}
      detailsPath={getProductPath(entity)}
    />
  );
};

DynamicProductCard.propTypes = {
  blok: PropTypes.shape({
    product: PropTypes.object,
    showDescription: PropTypes.bool,
    hoverOverlay: PropTypes.bool,
  }),
  productFromStory: PropTypes.object,
  collection: PropTypes.object,
};

export default memo(DynamicProductCard);
