import { find, get } from './nodash';
import deNodify from './deNodify';

const findImageForProduct = (images, productType) => {
  let imageMatcher;

  switch (productType) {
    case 'Tools':
      imageMatcher = '_web';
      break;
    case 'Toolskit':
      imageMatcher = '_web';
      break;
    case 'Supplies':
      imageMatcher = '_web';
      break;
    case 'Supplies Kit':
      imageMatcher = '_web';
      break;
    case 'Apparel':
      imageMatcher = '_web';
      break;
    case 'Giftcard':
      imageMatcher = '_slide';
      break;
    case 'Standard':
      imageMatcher = '_grid';
      break;
    case 'Interior Standard':
      imageMatcher = '_grid';
      break;
    case 'Exterior Standard':
      imageMatcher = '_grid';
      break;
    case 'Interior Semi-Gloss':
      imageMatcher = '_grid';
      break;
    case 'Exterior Semi-Gloss':
      imageMatcher = '_grid';
      break;
    case 'Semigloss':
      imageMatcher = '_grid';
      break;
    case 'Primer':
      imageMatcher = '_web';
      break;
    case 'Interior Primer':
      imageMatcher = '_web';
      break;
    case 'Exterior Primer':
      imageMatcher = '_web';
      break;
    case 'Wallcoverings':
      imageMatcher = 'slide3';
      break;
    default:
      imageMatcher = '_grid';
  }

  const match = find((x) => {
    return (
      (get('originalSrc', x) || get('src', x))
        .toLowerCase()
        .indexOf(imageMatcher.toLowerCase()) > -1 ||
      (get('originalSrc', x) || get('src', x)).toLowerCase().indexOf('_grid') >
        -1
    );
  }, deNodify(images));

  if (!match) {
    return null;
  }

  const url = get('src', match) || get('originalSrc', match);
  return url;
};

export default findImageForProduct;
